import React from 'react';
import '../styles/Ticker.css';

const Ticker = ({ text = 'Default Ticker Text', speed = 20, }) => {

  return (
    <div className="ticker-container">
      <div className="ticker-content">
        {/* Repeated ticker items for smooth looping */}
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>

        {/* Duplicate content for continuous loop */}
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>

        {/* Duplicate content for continuous loop */}
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>

        {/* Duplicate content for continuous loop */}
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
        <div className="ticker-item">{text}</div>
        <div className="ticker-dot">•</div>
      </div>
    </div>
  );
}

export default Ticker;
