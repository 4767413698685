import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/PassionProjects.css';
import Ticker from './Ticker';
// Image Assets
import ArtfulCribLogo from '../images/portfolio/artful-crib/logo.svg'
import ArtfulCribImg from '../images/portfolio/artful-crib/passion-project.png';
import BangleBandLogo from '../images/portfolio/bangle-and-band/logo.svg';
import BangleBandImg from '../images/portfolio/bangle-and-band/passion-project.png';

const PassionProjects = () => {
  return (
    <section className="passion-projects-container py-5">
      <Ticker text="passion projects" />
      <div className="container">
        {/* Project 1: Artful Crib */}
        <div className="row align-items-center mb-5">
          <div className="col-12 col-md-6 order-2 order-md-1 artful-crib">
            <img className="passion-projects-logo" src={ArtfulCribLogo} />
            <p>
              <a href="https://artfulcrib.com" target="_blank" rel="noopener noreferrer">
                ArtfulCrib.com
              </a>{' '}
              is a curated online space dedicated to elevating the aesthetics of nursery decor
              through artistic inspiration and thoughtful design. As the creator, I envisioned
              and executed every aspect of the platform, from its user-friendly interface to
              its cohesive brand identity.
            </p>
            <p>
              I led the design and development process, ensuring that every detail reflects the
              project's core mission of making artful living accessible. This passion project
              allowed me to merge my love for design and storytelling, showcasing my ability to
              create immersive and impactful digital experiences.
            </p>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2 text-center">
            <img
              src={ArtfulCribImg} /* Replace with your image */
              alt="Artful Crib Project"
              className="img-fluid rounded"
            />
          </div>
        </div>

        {/* Project 2: Bangle & Band */}
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-center">
            <img
              src={BangleBandImg} /* Replace with your image */
              alt="Bangle & Band Project"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-12 col-md-6 passion-projects">
            <img className="passion-projects-logo" src={BangleBandLogo} />
            <p>
              <a href="https://bangleandband.com" target="_blank" rel="noopener noreferrer">
                Bangle & Band
              </a>{' '}
              is a vibrant online storefront showcasing the ingenuity of my jewelry pieces. 
              Born from my vision to beautifully conceal a hair tie, I led every aspect of 
              the project. From product design and manufacturing to creating a distinctive 
              brand identity that resonates with both wholesalers and customers. Through 
              meticulous attention to detail, I developed a seamless e-commerce experience 
              that emphasizes elegance and accessibility. 
            </p>
            <p>
              This project highlights my ability 
              to take an idea from concept to production, blending creativity, technical expertise, 
              and strategic branding into a cohesive and impactful platform.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PassionProjects;