import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Footer.css';
import AnimationContactButton from './AnimationContactButton';
// Image Assets
import logo from '../images/michelle-maltbia-logo.svg';
import ContactButton from '../images/contact-button.svg';

const Footer = () => {
  return (
    <footer className="footer py-4">
      <div className="container-fluid">
        <div className="row align-items-center">
          {/* Left Section: Name */}
          <div className="col-6 col-md-3 text-start mb-3 mb-md-0">
            <a className="navbar-brand d-flex align-items-center" href="/">
              <img src={logo} alt="Logo" height="40" />
            </a>
          </div>

          {/* Center Section: Links */}
          <div className="col-12 col-md-6 text-center">
            <div className="footer-links d-flex flex-wrap justify-content-center gap-4">
              <a href="/about" className="footer-link">About</a>
              <a href="/#portfolio" className="footer-link">Portfolio</a>
              <a href="/my-process" className="footer-link">My Process</a>
              <a href="/recent-thoughts" className="footer-link">Recent Thoughts</a>
            </div>
          </div>

          {/* Right Section: Contact Button */}
          <div className="col-6 col-md-3 text-end contact-svg">
            <div className="footer-contact">
              <a href="mailto:hi@michellemaltbia.com">
                <AnimationContactButton />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
