import React, { useEffect, useRef } from "react";
import Lottie from 'react-lottie-player';
import ContactButtonJSON from "../images/lottie-contact-button.json";

const AnimationContactButton = () => {
    const lottieRef = useRef(null);

    useEffect(() => {
        if (lottieRef.current) {
            lottieRef.current.play();
        }
    }, []);

    return (
        <div className="contact-svg">
        <Lottie
            ref={lottieRef}
            autoPlay
            loop
            animationData={ContactButtonJSON}
            style={{ height: "4.5rem", width: "100px" }}
        />
    </div>
    );
};

export default AnimationContactButton;