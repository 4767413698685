import React from 'react';
import '../styles/LockedItems.css';

import RelatedPortfolioCarousel from './RelatedPortfolioItems';

const LockedItems = () => {
  return (
    <div className="locked-items-container">
        <h1>🔒 Locked Portfolio Item</h1>
        <p>These items are currently locked for viewing.</p>
        <p>
        Due to confidentiality, I am unable to share screens or detailed information related to some of my work directly on this website. 
        </p>
        <p>
        I am happy to demonstrate some designs and discuss them further during an interview or private session. <a href="mailto:hi@michellemaltbia.com">Please reach out to learn more!</a>
        </p>
      <RelatedPortfolioCarousel />
    </div>
  );
};

export default LockedItems;
