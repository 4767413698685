// PostItStack.js
import React from 'react';
import '../styles/PostItStack1.css';

const PostItStack1 = () => {
    const notes = [
      "User-centered Design",
      "Wireframing & Prototyping",
      "Design Systems",
      "Interaction Design",
      "Visual Design"
    ];
  
    return (
      <div className="post-it-stack">
        {notes.map((text, index) => (
          <div className="post-it-container">
            <div key={index} className={`post-it post-it-${index + 1}`}>
                <p className="note-text">{text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default PostItStack1;