import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';

// Stylesheets & Fonts
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-poppins';
// Components
import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import Ticker from './components/Ticker';
import Skills from './components/Skills';
import PassionProjects from './components/PassionProjects';
import MyExperience from './components/MyExperience';
import Footer from './components/Footer';
import LockedItems from './components/LockedItems';
// Coming Soon Page
import ComingSoon from './components/ComingSoon';
// Portfolio
import PortfolioDetail from './components/PortfolioDetail';
// Assets
import logo from './images/michelle-maltbia-logo.svg';
import contactPlaceholder from './images/contact-button.svg';
import ContactButton from './components/AnimationContactButton'
import AnimationContactButton from './components/AnimationContactButton';

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for the drawer

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev); // Toggle the drawer state
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const carouselRef = useRef(null);

  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = 428; // Width of one portfolio item + gap
      carouselRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const updatePageIndicator = () => {
    if (carouselRef.current) {
      const scrollLeft = carouselRef.current.scrollLeft;
      const pageWidth = 428; // Width of one portfolio item + gap
      const currentPage = Math.round(scrollLeft / pageWidth);
      setCurrentPage(currentPage);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', updatePageIndicator);
      return () => carousel.removeEventListener('scroll', updatePageIndicator);
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navigation
          logo={logo}
          contactPlaceholder={contactPlaceholder}
          toggleDrawer={toggleDrawer}
          isDrawerOpen={isDrawerOpen}
        />
        <Routes>
          {/* Home Route */}
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Portfolio
                  carouselRef={carouselRef}
                  scrollCarousel={scrollCarousel}
                  currentPage={currentPage}
                />
                <Ticker text="my skills" />
                <Skills />
                {/* <Ticker text="passion projects" /> */}
                <PassionProjects />
                <Ticker text="my experience" />
                <MyExperience />
              </>
            }
          />
          {/* Portfolio Detail Page */}
          <Route path="/portfolio/:id" element={<PortfolioDetail />} />
          <Route path="/about" element={<ComingSoon />} />
          <Route path="/recent-thoughts" element={<ComingSoon />} />
          <Route path="/my-process" element={<ComingSoon />} />
          <Route path="/portfolio/locked" element={<LockedItems />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}



export default App;
