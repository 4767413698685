// PostItStack.js
import React from 'react';
import '../styles/PostItStack2.css';

const PostItStack2 = () => {
    const notes = [
      "HTML5 & SCSS",
      "React.js & JavaScript",
      "Responsive Design",
      "Bootstrap",
      "Git"
    ];
  
    return (
      <div className="post-it-stack">
        {notes.map((text, index) => (
          <div className="post-it-container">
            <div key={index} className={`post-it post-it-${index + 6}`}>
                <p className="note-text">{text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default PostItStack2;