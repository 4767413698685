import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Portfolio.css';

// Arrow SVGs
import ArrowRight from '../images/portfolio/Arrow-Right.svg'
import ArrowLeft from '../images/portfolio/Arrow-Left.svg'

// Portfolio Cover Images
import NestleCover from '../images/portfolio/nestle-purina/cover.svg';
import WalmartCover from '../images/portfolio/walmart/cover.svg';
import TransportationCover from '../images/portfolio/transportation-company/cover.svg';
import HDpianoCover from '../images/portfolio/hdpiano/cover.svg';
import PharmscriptCover from '../images/portfolio/pharmscript/cover.svg';
import UnRulyFarmCover from '../images/portfolio/unruly-farm/cover.svg';
import ArtfulCribCover from '../images/portfolio/artful-crib/cover.svg';
import BCGCover from '../images/portfolio/bcg/cover.svg';
import AvodahCover from '../images/portfolio/avodah/cover.svg';
import HeartSoilCover from '../images/portfolio/heart-soil/cover.svg';

function Portfolio({ carouselRef, scrollCarousel }) {
  const [currentPage, setCurrentPage] = useState(1); // Start with page 1
  const navigate = useNavigate();

  const portfolioItems = [
    { id: 1, title: 'Nestle Purina', image: NestleCover, locked: false },
    { id: 2, title: 'Walmart', image: WalmartCover, locked: false },
    { id: 3, title: 'Transportation', image: TransportationCover, locked: true },
    { id: 4, title: 'HDpiano', image: HDpianoCover, locked: false },
    { id: 5, title: 'Pharmscript', image: PharmscriptCover, locked: true },
    { id: 6, title: 'Unruly Farm', image: UnRulyFarmCover, locked: false },
    { id: 7, title: 'Artful Crib', image: ArtfulCribCover, locked: false },
    { id: 8, title: 'Board of Certified Genealogists', image: BCGCover, locked: true },
    { id: 9, title: 'Avodah', image: AvodahCover, locked: true },
    { id: 10, title: 'Heart & Soil', image: HeartSoilCover, locked: false },
  ];

  const updatePageIndicator = () => {
    if (carouselRef.current) {
      const scrollLeft = carouselRef.current.scrollLeft;
      const pageWidth = 348; // Width of one portfolio item
      const currentPageIndex = Math.round(scrollLeft / pageWidth) + 1; // Add 1 to match human-readable pages
      setCurrentPage(currentPageIndex);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', updatePageIndicator);
      return () => carousel.removeEventListener('scroll', updatePageIndicator);
    }
  }, [carouselRef]);

  return (
    <section id="portfolio" className="portfolio-section position-relative">
        {/* Controls */}
      <div className="portfolio-controls d-flex align-items-center gap-2">
        {/* Page Indicator */}
        <span className="page-indicator">
          {currentPage}/{portfolioItems.length}
        </span>
        <div className="button-container">
            {/* Left Arrow */}
            <button
            className="btn btn-light portfolio-arrow"
            onClick={() => scrollCarousel('left')}
            style={{ opacity: 0.7 }}
            >
                <img src={ArrowLeft} />
            </button>
            {/* Right Arrow */}
            <button
            className="btn btn-light portfolio-arrow"
            onClick={() => scrollCarousel('right')}
            style={{ opacity: 0.7 }}
            >
                <img src={ArrowRight} />
            </button>
        </div>
      </div>
      {/* Carousel Container */}
      <div
        ref={carouselRef}
        className="portfolio-carousel d-flex overflow-hidden"
        style={{
          scrollSnapType: 'x mandatory',
          gap: '20px',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {portfolioItems.map((item, index) => (
          <div
            key={item.id}
            className={`portfolio-item flex-shrink-0 ${index === 0 ? 'first-item' : ''}`}
            onClick={() =>
              item.locked ? navigate('/portfolio/locked') : navigate(`/portfolio/${item.id}`)
            }
            style={{ cursor: 'pointer' }}
          >
            <img
              src={item.image}
              alt={item.title}
              className="img-fluid rounded"
            />
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Portfolio;
