import React from 'react';
import AnimationContactButton from '../components/AnimationContactButton';
// Stylesheet
import '../styles/Navigation.css'

function Navigation({ logo, contactPlaceholder, toggleDrawer, isDrawerOpen }) {
  return (
    <div>
      <nav
        className="navbar"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1050, // Ensures it's above all other content
        }}
      >
        <div className="container-fluid px-4 d-flex justify-content-between align-items-center">
          {/* Logo */}
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={logo} alt="Logo" height="40" />
          </a>

          {/* Hamburger Button */}
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            onClick={toggleDrawer}
            aria-controls="drawerNav"
            aria-expanded={isDrawerOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Desktop Navigation */}
          <div className="d-none d-lg-block">
            <ul className="navbar-nav flex-row mx-auto">
              <li className="nav-item mx-3">
                <a className="nav-link" href="/about">About</a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="#portfolio">Portfolio</a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/my-process">My Process</a>
              </li>
              <li className="nav-item mx-3">
                <a className="nav-link" href="/recent-thoughts">Recent Thoughts</a>
              </li>
            </ul>
          </div>

          {/* Contact Button */}
          <div className="d-none d-lg-block">
            <a href="mailto:hi@michellemaltbia.com">
              {/* <img src={contactPlaceholder} alt="Contact Button" height="50" /> */}
              <AnimationContactButton />
            </a>
          </div>
        </div>
      </nav>

      {/* Slide-in Drawer Menu for Mobile */}
      <div
        id="drawerNav"
        className={`drawer ${isDrawerOpen ? 'open' : ''} d-lg-none`}
        style={{
            position: 'fixed',
            top: 0,
            right: 0,
            height: '100%',
            width: '250px',
            backgroundColor: '#fff',
            boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.2)',
            transform: isDrawerOpen ? 'translateX(0)' : 'translateX(100%)',
            transition: 'transform 0.3s ease-in-out',
            zIndex: 2000, // Ensure the drawer has a higher z-index
            padding: '1rem',
        }}
        >
        <button
          type="button"
          className="btn-close mb-4"
          aria-label="Close"
          onClick={toggleDrawer}
        ></button>
        <ul className="navbar-nav">
          <li className="nav-item mb-2">
            <a className="nav-link" href="#about">About</a>
          </li>
          <li className="nav-item mb-2">
            <a className="nav-link" href="#portfolio">Portfolio</a>
          </li>
          <li className="nav-item mb-2">
            <a className="nav-link" href="#process">My Process</a>
          </li>
          <li className="nav-item mb-2">
            <a className="nav-link" href="#thoughts">Recent Thoughts</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navigation;
