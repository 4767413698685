import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/RelatedPortfolioItems.css';

// Portfolio Cover Images
import NestleCover from '../images/portfolio/nestle-purina/cover.svg';
import WalmartCover from '../images/portfolio/walmart/cover.svg';
import HDpianoCover from '../images/portfolio/hdpiano/cover.svg';
import UnRulyFarmCover from '../images/portfolio/unruly-farm/cover.svg';
import ArtfulCribCover from '../images/portfolio/artful-crib/cover.svg';

const relatedPortfolioItems = [
    { id: 1, title: 'Nestle Purina', image: NestleCover },
    { id: 2, title: 'Walmart', image: WalmartCover },
    { id: 4, title: 'HDpiano', image: HDpianoCover },
    { id: 6, title: 'Unruly Farm', image: UnRulyFarmCover },
    { id: 7, title: 'Artful Crib', image: ArtfulCribCover },
];

const RelatedPortfolioCarousel = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const currentProjectId = parseInt(id);
  
    const handleNavigation = (projectId) => {

        window.location.href = `/portfolio/${projectId}`;

    };
  
    const relatedProjects = relatedPortfolioItems.filter((item) => item.id !== currentProjectId);

  return (
    <section className="new-portfolio-carousel-section pb-5">
      <h5 className="text-center my-5">Explore More Projects</h5>
      <div className="new-portfolio-carousel d-flex overflow-hidden justify-content-center">
        {relatedProjects.map((item) => (
          <div
            key={item.id}
            className="new-portfolio-item flex-shrink-0"
            onClick={() => handleNavigation(item.id)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={item.image}
              alt={item.title}
              className="img-fluid rounded"
            />
            <p className="text-center mt-2">{item.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RelatedPortfolioCarousel;
