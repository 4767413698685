import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Ensure the scroll position resets to the top-left corner
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Avoid scrolling animation
    });
  }, [pathname]); // Run this effect whenever the route changes

  return null; // This component does not render anything
}
