import React, { useState, useRef, useEffect } from 'react';

import '../styles/Hero.css';

function Hero() {

    return(
        <div>
            <section className="hero py-5">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8 px-0">
                    <h1 className="display-3">Senior<br />Product Designer<br />with a user-centered<br />approach.</h1>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6 px-0">
                    <p className="lead">
                        Each project showcased here is a piece of a larger narrative about my design journey and the values I bring to the table: empathy, functionality, and aesthetics.
                    </p>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}

export default Hero;