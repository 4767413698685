// PostItStack.js
import React from 'react';
import '../styles/PostItStack3.css';

const PostItStack3 = () => {
    const notes = [
      "Project Leadership",
      "Design-to-Development Handoff",
      "Stakeholder Communication",
      "Workshops & Design Sprints",
      "Cross-Functional Team Facilitation",
      "Iterative Problem Solving"
    ];
  
    return (
      <div className="post-it-stack">
        {notes.map((text, index) => (
          <div className="post-it-container">
            <div key={index} className={`post-it post-it-${index + 11}`}>
                <p className="note-text">{text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default PostItStack3;