import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Portfolio from './Portfolio';
import '../styles/PortfolioDetail.css'
import portfolioData from '../data/Portfolio.json';
import RelatedPortfolioCarousel from './RelatedPortfolioItems';

function PortfolioDetail() {
  const { id } = useParams(); // Extract dynamic id from the URL
  const project = portfolioData.find((item) => item.id === parseInt(id)); // Find the matching project
  
  const images = require.context('../images/portfolio', true); 
  const headerImage = images(`./${project.header_image}`);
  const img1 = images(`./${project.additionalMedia1}`);
  const img2 = images(`./${project.additionalMedia2}`);


  return (
    <div>
        <div className="portfolio-detail-header">
            <div className="container">
                <h5 className="project-subtitle">{project.subtitle}</h5> 
                <h1 className="project-detail-title">{project.title}</h1>
            </div>
            <div className="container">
                <img className="hero-image" src={headerImage} alt="" />
            </div>
        </div>
        <div className="portfolio-detail-content">
            <div className="container">
                <div className="row">
                    {/* Overview */}
                    <div className="col-lg-6">
                        <div className="row my-5">
                            <h5>Overview</h5>
                            <h2>{project.overview_title}</h2>
                            <ul className="role-tags">
                                {project.overview_tags.map((tag, index) => (
                                    <li className="role-tag" key={index}>{tag}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* Overview */}
                        <div className="row my-5">
                            <p>{project.overview}</p>
                        </div>
                        {/* Problem Statement */}
                        <div className="row my-5">
                            <h5>Problem Statement</h5>
                            <p>{project.problem}</p>
                        </div>
                    </div>
                </div>
                {/* The Process */}
                <div className="row">
                    {/* Process */}
                    <div className="col-lg-6 pr-4">
                        <div className="row my-5">
                            <h5>The Process</h5>
                            <h2>{project.process_subtitle}</h2>
                        </div>
                        <div className="row">
                            <img className="portfolio-detail-img" src={img1} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* Discovery & Research */}
                        <div className="row my-5">
                            <p>{project.process.discovery_and_research}</p>
                        </div>
                        {/* Design & Prototyping */}
                        <div className="row my-5">
                            <h5>Design & Prototyping</h5>
                            <p>{project.process.design_and_prototyping}</p>
                        </div>
                        {/* Testing & Iteration */}
                        <div className="row my-5">
                            <h5>Testing & Iteration</h5>
                            <p>{project.process.testing_and_iteration}</p>
                        </div>
                    </div>
                </div>
                {/* The Solution */}
                <div className="row">
                    {/* Solution */}
                    <div className="col-lg-6 pr-4">
                        <div className="row my-5">
                            <h5>The Solution</h5>
                            <h2>{project.solution_subtitle}</h2>
                        </div>
                        <div className="row">
                            <img className="portfolio-detail-img" src={img2} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* Bullet Points */}
                        <div className="row my-5">
                            <h5>Features</h5>
                            <ul>
                                {project.solution.map((solution, index) => (
                                    <li key={index}>{solution}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="row my-5">
                            <h5>Technology Stack</h5>
                            <ul className="role-tags">
                                {project.technologies.map((tech, index) => (
                                    <li className="role-tag" key={index}>{tech}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="row my-5">
                        <h5>Results</h5>
                            <ul>
                                {project.results.map((result, index) => (
                                    <li key={index}>{result}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="row my-5">
                            <h5>Conclusion</h5>
                            <p>{project.conclusion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <RelatedPortfolioCarousel />
    </div>
  );
}

export default PortfolioDetail;
