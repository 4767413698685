import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Skills.css';
import AnimationContactButton from './AnimationContactButton'; // Animation for Design Expertise
// import CardStack from './CardStack';
import PostItStack1 from './PostItStack1';
import PostItStack2 from './PostItStack2';
import Skills1 from '../images/my-skills/my-skills-1.svg'
import PostItStack3 from './PostItStack3';

function Skills() {
    return (
      <section id="skills" className="skills-container py-5">
        <div className="container">  
          {/* Row 1: Text (left), Visual (right) */}
          <div className="row mb-5 align-items-center">
            <div className="col-11 col-md-6 order-2 order-md-1 skills-item mx-auto">
              <h3 className="skills-heading">🎨 Design Expertise</h3>
              <br />
              <p>
              I specialize in user-centered design, creating intuitive and engaging experiences that prioritize user needs. My expertise includes wireframing, prototyping, and crafting cohesive design systems that ensure consistency and streamline development. With strengths in interaction and visual design, I deliver accessible and inclusive solutions that captivate users and meet business goals.
              </p>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2 text-center">
              <div className="skills-visual-1">
                {/* <CardStack /> */}
                <PostItStack1 />
              </div>
            </div>
          </div>
  
          {/* Row 2: Visual (left), Text (right) */}
          <div className="row mb-5 align-items-center">
            <div className="col-12 col-md-6 text-center">
              <div className="skills-visual-2">
                {/* <CardStack /> */}
                <PostItStack2 />
              </div>
            </div>
            <div className="col-11 col-md-6 skills-item mx-auto">
              <h3 className="skills-heading">💻 Development Knowledge</h3>
              <br />
              <p>
              I specialize in front-end development with expertise in HTML5, CSS3, JavaScript, and responsive design using modern frameworks like React.js. My proficiency with Bootstrap enables me to create sleek, adaptable interfaces efficiently. I excel in component-driven development and effective collaboration through Git. This skillset allows me to rapidly build working screens for user testing, delivering high-quality, scalable solutions.
              </p>
            </div>
          </div>
  
          {/* Row 3: Text (left), Visual (right) */}
          <div className="row align-items-center">
            <div className="col-11 col-md-6 order-2 order-md-1 skills-item mx-auto">
              <h3 className="skills-heading">⚖️ Collaboration & Leadership</h3>
              <br />
              <p>
              I excel in cross-functional collaboration, facilitating seamless design-to-development handoffs and aligning teams around shared goals. With experience in research, user testing, and workshops, I foster innovation and iterative problem-solving. My approach emphasizes clear communication and structured processes to deliver impactful results efficiently. Through project leadership and design sprints, I bridge gaps, inspire creativity, and drive success in dynamic environments.
              </p>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2 text-center">
              <div className="skills-visual-3">
                <PostItStack3 />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default Skills;