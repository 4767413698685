import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/MyExperience.css';
import Resume from '../images/resume.png'
import DownloadIcon from '../images/Download.svg';
import ResumeFile from '../Michelle_Maltbia_Resume.pdf';

const MyExperience = () => {
  return (
    <section id="experience" className="my-experience py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: Resume Image */}
          <div className="col-12 col-md-5 text-center mb-4 mb-md-0">
            <div class="row">
              <img
                src={Resume}
                alt="Resume"
                className="img-fluid resume-image"
              />
            </div>
            <div className="download-resume mt-2">
              <a href={ResumeFile} download className="text-dark text-decoration-none">
                <img src={DownloadIcon} />Download Resume
              </a>
            </div>
          </div>

          {/* Right Side: Experience Text */}
          <div className="col-12 col-md-7">
            <h2 className="experience-title">
              9+ years of Design & Development Experience
            </h2>
            <p className="experience-description">
              Each project showcased here reflects my dedication to crafting user-centered designs that blend empathy, functionality, and aesthetics. My expertise spans design systems, front-end development, and cross-functional collaboration to deliver impactful, high-quality solutions. Let’s explore how my work can drive meaningful results for users and businesses alike. <br />
            </p>
            <div className="role-tags d-flex gap-2 mt-4 flex-wrap">
              <span className="role-tag">Front-End Engineer</span>
              <span className="role-tag">UX Designer</span>
              <span className="role-tag">Design Technologist</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyExperience;
